import { Notify } from 'vant-green';
import i18n from '../utils/i18n.js';

var networkConfig = null;
var ethereum = null;
async function checkEthereum(){
    if (window.ethereum) {
        ethereum = window.ethereum;
    } else if (window.web3) {
        ethereum = window.web3.currentProvider;
    } else {
        Notify(
            {
                type: 'danger',
                message: i18n.t("methods.t0"),
                duration: 10000
            }
        );
        throw new Error(i18n.t("methods.t0"));
    }

    // 监听账户变化事件
    ethereum.on('accountsChanged', (accounts) => {
        console.log("accountsChanged", accounts);
        window.location.reload();
    });

    // 监听网络变化事件
    ethereum.on('chainChanged', (chainId) => {
        console.log("chainChanged", chainId);
        window.location.reload();
    });
}

// 连接钱包并检查网络
export async function connectWallet(config) {
   
    try {
        await checkEthereum();
        if(ethereum == null){
            return false;
        }else{
            networkConfig = {
                chainId: this.$utils.int2hex(config.chainId, 1), // 网络链 ID，必须是十六进制
                chainName: config.chainName, // 网络名称
                nativeCurrency: {
                    name: config.chainToken, // 本地代币名称
                    symbol: config.chainToken, // 本地代币符号
                    decimals: 18, // 本地代币小数点位数
                },
                rpcUrls: config.chainRPC, // RPC 节点 URL 列表
                blockExplorerUrls: config.blockExplorerUrls, // 区块浏览器 URL 列表
            };
            //检查网络是否存在，不存在则添加
            await checkNetwork(config);
            const account = await getWalletAddress();
            console.log('account', account);
            const signature = localStorage.getItem("signature_"+account);
            if(signature == null){
                await signMessage(account).then(async (sign)=>{
                    localStorage.setItem("signature_"+account, sign);
                    localStorage.setItem("userAccount", account);
                }).catch((err)=>{
                    console.log(err);
                    localStorage.removeItem("userAccount");
                    Notify({ type: 'danger', message: 'Signature error' });
                    throw new Error('Signature error');
                })
            }else{
                localStorage.setItem("userAccount", account);
            }
            return account;
        }
    } catch (Error) {
        console.log(Error); 
        throw Error;
    }
}

// 获取用户钱包地址
async function getWalletAddress() {
    const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
    return accounts[0];
}
function utf8ToHex(str) {
    return Array.from(str).map(c => c.charCodeAt(0).toString(16).padStart(2, '0')).join('');
}
// 重写拉起签名消息
async function signMessage(address) {
    return await ethereum.request({ method: 'personal_sign', params: [utf8ToHex(address), address, ""] });
}
// 检查并切换网络
async function checkNetwork(config) {
    const chainId = await ethereum.request({method: 'eth_chainId'})
    if (chainId !== networkConfig.chainId) {
        try {
            await ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: networkConfig.chainId }],
            })
        } catch (error) {
            console.error('切换网络失败:', error);
            // alert(error.code);
            if (error.code === 4902 || error.code === -32603 || error.code === 4001) {
                // 网络未添加到 MetaMask，尝试添加网络
                localStorage.removeItem("userAccount");
                await addNetwork();
                await connectWallet(config);
            } else {
                throw error;
            }
        }
    }
}

// 添加网络到 MetaMask
async function addNetwork() {
    try {
        await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkConfig],
        });
    } catch (error) {
        console.error('添加网络失败:', error);
        throw error;
    }
}

