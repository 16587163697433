import { ethers } from 'ethers';
import MineABI from '../../abi/Mine.json';
import ERC20ABI from '../../abi/ERC20ABI.json';
import UniSwapABI from '../../abi/uniswap.json';
import { Notify } from 'vant-green';
import { Toast } from 'vant';
import i18n from '../utils/i18n.js';
import store from '../store';

class CollectContract {
    constructor(address = '') {
        var ethereum = null;
        if (window.ethereum) {
            ethereum = window.ethereum;
            // console.log("Running in Ethereum");
        } else if (window.web3) {
            ethereum = window.web3.currentProvider;
            // console.log("Running in Web3");
        } else {
            Notify({
                type: 'danger',
                message: '401:' + i18n.t("methods.t0")
            });
            throw new Error('401:' + i18n.t("methods.t0"));
        }
        this.address = address;
        this.provider = new ethers.providers.Web3Provider(ethereum);
        this.signer = this.provider.getSigner();
    }
    //调用方法
    async call(action, method, params = []) {
        try {
            var contract = await this.handle(action);
            if (params.length > 0) {
                return await contract[method](...params);
            } else {
                return await contract[method]();
            }
        } catch (error) {
            console.log('合约报错：',action,method,params)
            this.errorHandle(error);
            throw error;
        }
    }
    //调用方法
    async send(action, method, params = []) {
        console.log("Send Action:", action, method, params);
        try {
            //匹配合约
            var contract = await this.handle(action);
            //查询gasPrice
            // const gasPrice = (await this.provider.getGasPrice()).add(0.01 * 1e9); //多加 0.01 Gwei
            let gasPrice = 0;
            if(store.state.config.chainId == 56){
                gasPrice = (await this.provider.getGasPrice()).add(0.01 * 1e9); //多加 0.01 Gwei
            }else{
                gasPrice = 3 * 1e9;
            }
            
            // 设置执行选项
            const options = {
                gasPrice: gasPrice
            };
            // 估算 gasLimit
            const estimateGasParams = params.length > 0 ? [...params, options] : [options];
            const gasLimit = (await contract.estimateGas[method](...estimateGasParams)).add(10000); // 多加 1 万 gasLimit
            // 更新 gasLimit
            options.gasLimit = gasLimit;
            console.log("GasLimit:", gasLimit.toString());
            console.log("gasPrice", gasPrice / 1e9, "Gwei");
            const gasUsed = gasLimit.mul(gasPrice);
            console.log("gas预计总花费:", gasUsed / 1e18, "BNB");
            //查询我的gas
            const balance = await this.signer.getBalance();
            console.log("BNB余额:", balance.toString() / 1e18, "BNB");
            if(balance.lt(gasUsed)){
                Notify({
                    type: 'danger',
                    message: "BNB余额不足，请充值"
                });
                throw new Error("BNB余额不足，请充值");
            }
            console.log("Send Params:", params);

            // 执行合约
            const txParams = params.length > 0 ? [...params, options] : [options];
            const txSell = await contract[method](...txParams);
            await txSell.wait();
            console.log("合约执行结果：", txSell);
            return txSell;
        } catch (error) {
            console.log('合约报错：',action,method,params)
            this.errorHandle(error);
            throw error;
        }
    }
    //处理action，返回具体的合约是哪个
    async handle(action) {
        var contract = null;
        switch (action) {
            case 'mine':
                contract = new ethers.Contract(store.state.config.mine, MineABI, this.signer);
                break;
            case 'erc20':
                contract = new ethers.Contract(this.address, ERC20ABI, this.signer);
                break;
            case 'usdt':
                contract = new ethers.Contract(store.state.config.usdtTokenAddress, ERC20ABI, this.signer);
                break;
            case 'uniswap':
                contract = new ethers.Contract(store.state.config.UniswapV2Router, UniSwapABI, this.signer);
                break;
            default:
                break;
        }
        return contract;
    }

    // 处理错误
    async errorHandle(error) {
        console.log('合约报错：',error);
        var error_message = '未知错误，合约执行失败';
        if(error.reason || error.code){
            console.log('合约报错信息：',error.reason);
            const msg = error.reason ? error.reason : "";
            const errs = msg.split('reverted: ');
            
            // 其他未知错误
            if(errs.length > 1 && errs[1].length > 0){
                //取出错误信息
                const e = errs[1];
                switch (e) {
                    case 'Insufficient allowance':
                        error_message = i18n.t("methods.t19");   //授权额度不足
                        break;
                    case 'Device not available':
                        error_message = "此设备不可用";
                        break;
                    case 'Insufficient mining frequency':
                        error_message = "投资挖矿次数不足，每邀请一人增加1次投资挖矿次数";
                        break;
                    case 'father not exist':
                        error_message = "您绑定的上级不存在系统中";
                        break;
                    case 'Insufficient balance':
                        error_message = "代币余额不足";
                        break;
                    case 'Reward distribution failed':
                        error_message = "底池奖励发放失败";
                        break;
                    case 'Blacklist has no authority to operate':
                        error_message = "黑名单用户无权操作";
                        break;
                    case 'Amount must be greater than 0':
                        error_message = "数量必须大于0";
                        break;
                    case 'The slippage cannot be negative':
                        error_message = "滑点不能为负数";
                        break;
                    case 'Insufficient available funds':
                        error_message = "您的可用资金不足";
                        break;
                    case 'Transfer Failed':
                        error_message = "转账失败";
                        break;
                    case 'Approve Swap Failed':
                        error_message = "合约授权失败";
                        break;
                    case 'Pool Not created':
                        error_message = "领取未开放，等待底池搭建完成";
                        break;
                    case 'Insufficient liquidity':
                        error_message = "底池流动性不足，无法完成交易";
                        break;
                    default:
                        error_message = e || '未知错误，合约执行失败';
                        break;
                }
            }else{
                // 处理钱包错误
                switch (error.code) {
                    case 'ACTION_REJECTED':
                    case 4001:
                        error_message = i18n.t("methods.t16"); // 用户拒绝交易
                        break;
                    case 'CALL_EXCEPTION':
                        error_message = i18n.t("methods.t23"); // 合约调用异常（大概率网络异常）
                        break;
                    case 'INSUFFICIENT_FUNDS':
                        error_message = i18n.t("methods.t18"); // 用户的链币余额不足
                        break;
                    case 'INSUFFICIENT_ALLOWANCE':
                        error_message = i18n.t("methods.t19"); // 授权额度不足
                        break;
                    case 'INSUFFICIENT_VALUE':
                        error_message = i18n.t("methods.t20"); // 提供的交易价值不足
                        break;
                    case 'INVALID_ARGUMENT':
                        if (error.reason === 'invalid hash') {
                            error_message = i18n.t("methods.t16"); // 用户拒绝交易
                        } else {
                            error_message = i18n.t("methods.t21"); // 参数无效
                        }
                        break;
                    case 'UNPREDICTABLE_GAS_LIMIT':
                        error_message = i18n.t("methods.t21"); // 无法预测 Gas 限制
                        break;
                    case 'NETWORK_ERROR':
                        error_message = i18n.t("methods.t23"); // 网络问题
                        break;
                    case 'TIMEOUT':
                        error_message = i18n.t("methods.t24"); // 请求超时
                        break;
                    case 'NONCE_EXPIRED':
                        error_message = i18n.t("methods.t25"); // Nonce 过期
                        break;
                    case 'REPLACEMENT_UNDERPRICED':
                        error_message = i18n.t("methods.t26"); // 交易 gas 价格过低
                        break;
                    case 'TRANSACTION_REPLACED':
                        error_message = i18n.t("methods.t27"); // 交易被替换
                        break;
                    case 'OUT_OF_GAS':
                        error_message = i18n.t("methods.t28"); // Gas 耗尽
                        break;
                    case 'INVALID_SIGNATURE':
                        error_message = i18n.t("methods.t29"); // 签名无效
                        break;
                    default:
                        // 未知错误
                        error_message =  error.error && error.error.data && error.error.data.message
                        ? error.error.data.message
                        : error.message ? error.message : i18n.t("methods.t30")
                        break;
                }
            }
        }
        Notify({
            type: 'danger',
            message: error_message
        });
    }
}

export default CollectContract;
