<template>
	<div id="app" v-if="isLoading">
		<Head @custom-event="ChangeAccount" />
		<router-view ref="routerView" />
	</div>
	<div v-else class="connect_wallet">
		<div class="logo_box">
			<img src="./assets/get.png" class="logo" />
		</div>
		<div class="box">
			<div class="load_box">
				<!-- 链接钱包 -->
				<van-button type="primary" @click="cw" class="loading_btn" :loading="!reConnect" :loading-text="$t('head.t1')">{{ $t("head.t1") }}</van-button>
			</div>
		</div>
		<van-popup v-model="bindSuperior" round style="width: calc(100% - 30px)" :close-on-click-overlay="false">
			<bindSuperior @close="closeBind" class="bindSuperiorPop" />
		</van-popup>
	</div>
</template>
<script>
import Head from "@/components/Head.vue";
import bindSuperior from "@/components/BindSuperior.vue";
import { Notify } from "vant-green";

export default {
	components: {
		Head: Head,
		bindSuperior: bindSuperior,
	},
	data() {
		return {
			show: true,
			contract: null,
			isLoading: false,
			reConnect: false,
			superior: null,
			bindSuperior: false,
		};
	},
	mounted() {
		// alert(this.$signBytes);
		//获取语言设置
		this.$i18n.locale = localStorage.getItem("language") || "zh-hant";
		setTimeout(async () => {
			await this.cw();
		}, 100);
	},
	methods: {
		async cw() {
			try {
				this.reConnect = false;
				await this.get_chain_info();
				if (!this.$store.state.config) {
					return;
				}

				const account = await this.$connectWallet(this.$store.state.config);
				if (!account || account == this.$store.state.address0) {
					throw this.$i18n.t("methods.t0");
				}
				
				//查询用户信息上级地址
				const team_info = await this.getSuperior(account);
				console.log("team_info:", team_info);
				this.$store.state.team_info = team_info;

				//查询顶级地址
				const superiorTop = await this.getTop(team_info.superior);
				this.$store.state.superiorTop = superiorTop;

				if (this.$utils.isSameAddress(team_info.superior, this.$store.state.address0)) {
					// console.log("未绑定上级");
					//不是团队长
					if (!this.$utils.isSameAddress(account, superiorTop)) {
						//判断本地是否有绑定
						const localSuoerior = localStorage.getItem(this.$store.state.config.version + "_Superior_" + account);
						console.log("localSuoerior:", localSuoerior);
						//如果本地没有绑定，提示绑定
						if (!localSuoerior) {
							this.superior = this.$route.query.superior;
							this.bindSuperior = true;
							return;
						} else {
							this.superior = localSuoerior;
						}
					} else {
						this.$store.state.isTop = true;
						this.superior = this.$store.state.address0;
					}
				} else {
					this.superior = team_info.superior;
				}

				this.$store.state.superior = this.superior;
				setTimeout(() => {
					this.isLoading = true;
				}, 500);
			} catch (error) {
				this.reConnect = true;
			}
		},
		closeBind(isBind = false) {
			this.bindSuperior = false;
			if (isBind) {
				setTimeout(() => {
					this.isLoading = true;
				}, 500);
			} else {
				this.reConnect = true;
			}
		},
		ChangeAccount(account) {
			console.log("account changed:", account);
		},
		async get_chain_info() {
			try {
				const result = await this.$api.request("/get_chain_info");
				if (result.code == 200) {
					this.$store.state.config = result.data;
				} else {
					this.$store.state.config = null;
					Notify({
						type: "danger",
						message: result.msg,
					});
				}
			} catch (error) {
				this.$store.state.config = null;
				console.log(error);
				Notify({
					type: "danger",
					message: "Network error",
				});
			}

			return this.$store.state.config;
		},
		async getSuperior(account) {
			try {
				return await new this.$MyContract().call("mine", "teams", [account]);
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
		//查询顶级地址
		async getTop() {
			try {
				return await new this.$MyContract().call("mine", "getNodeByUser", [0]);
			} catch (error) {
				console.log(error);
				throw error;
			}
		},
	},
};
</script>
<style scoped lang="scss">
.connect_wallet {
	background-color: #1e2329;
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: hidden;
	.logo_box {
		flex-grow: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.logo_txt {
			width: 55%;
			margin-top: 20px;
		}
	}
	.logo {
		width: 35%;
		// margin: auto;
	}
	.logo2 {
		height: 22px;
	}
	.box {
		border-radius: 20px;
		text-align: center;
		padding-bottom: env(safe-area-inset-bottom);
		height: 25%;
		.load_box {
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
	.loading_img {
		height: 100%;
	}
	.title1 {
		margin-top: 40px;
		// font-family: PingFang-SC-Medium;
		font-size: 18px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 4px;
		color: #fff;
	}
	.title2 {
		margin-top: 13px;
		// font-family: PingFang-SC-Medium;
		font-size: 16px;
		font-weight: normal;
		line-height: 18px;
		letter-spacing: 2px;
		color: #dedede;
	}
}
.loading_btn {
	background-color: #fcd535;
	color: #000;
	width: 190px !important;
	height: 44px !important;
	border-radius: 8px !important;
	font-size: 16px;
}
</style>
<style lang="scss">
html {
	background: #0c0b08;
	max-width: 500px;
	margin: 0 auto;
	font-size: 12px;
	overflow-x: hidden;
}

body {
	margin: 0;
	padding: 0;
	background: #0c0b08;
	background-size: 100%;
	min-height: 100vh;
}

.red {
	color: #f44336 !important;
}

.default_btn {
	background-image: linear-gradient(0deg, #eacea1 0%, #d4aa77 100%);
	border-radius: 8px !important;
	width: 100%;
	transition: 300ms;
	user-select: none;
	border: none !important;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px !important;
	color: #0d0b08 !important;
	height: 44px !important;

	&:not([disabled="disabled"]):active {
		transform: scale(0.98);
		opacity: 0.85;
	}

	&.kongxing {
		background: #fff !important;
		border: solid 1px #cccccc !important;
		font-size: 14px !important;
		color: #ce9d00 !important;
		height: 36px !important;
	}
}
.cancel_default_btn {
	background: #333430 !important;
	border: none !important;
	color: #fff !important;
	border-radius: 8px !important;
	width: 100%;
	transition: 300ms;
	user-select: none;
	display: flex;
	align-items: center;
	justify-content: center;

	font-size: 14px !important;
	height: 44px !important;
	box-shadow: 0 0 0px 1px #4c4c46;
	&:active {
		transform: scale(0.98);
		opacity: 0.85;
	}
}

.dialog_box {
	text-align: left;
	line-height: 24px;
	padding: 0px 18px 0px 20px;
	color: #000;
	font-size: 13px;

	display: flex;
	flex-direction: column;
	flex-grow: 1;
	.content_box {
		flex-grow: 1;
	}
	.btn_box {
		// padding: 40px 0 30px;
	}
}
.none {
	display: flex;
	flex-direction: column;
	height: fit-content;
	justify-content: center;
	align-items: center;
	margin-top: 40px;
	user-select: none;
	color: #999;
	padding-bottom: 40px;
	font-size: 14px;
	img {
		width: 120px;
		margin-bottom: 10px;
	}
}

.content_box {
	padding: 0 15px;
}

.van-cell {
	border: none !important;
	background: none !important;
	padding: 0 !important;
}
.van-cell::after {
	border: none !important;
}

.van-field__button {
	width: 60px;
	height: 32px;
}

.my_sticky {
	position: absolute;
	left: 0px;
	right: 0px;
	top: 50px;
}

.van-sticky {
	background: transparent !important;
	padding: 10px 0px !important;
	transition: 500ms;
}
.van-sticky.van-sticky--fixed {
	background: rgb(255, 255, 255) !important;
	padding: 10px 16px !important;
	box-shadow: 0px 1px 5px 0px #d6d6d6 !important;
	max-width: calc(500px - 32px);
	margin: auto;
}
.van-tabs--line .van-tabs__wrap {
	height: 36px !important;
	border-radius: 18px !important;
	display: none;
}

.van-tab--active {
	background-color: rgba(37, 83, 234, 1);
	border-radius: 18px;
}

.van-notify {
	z-index: 3000 !important; /* 覆盖 Notify 的 z-index */
}



.van-tabbar--fixed {
	max-width: 500px;
	right: 0;
	margin: auto;
}
.van-tabbar,.van-tabbar-item--active{
	background-color: #0d0b08 !important;
}
.van-hairline--top-bottom::after{
	border: none !important;
	border-top: 1px solid #33332f !important;
}
.van-dialog{
	background-color:#262623 !important;
}
</style>
